import React from 'react';
import './App.css';
import Routing from './Routing.jsx';
import Practise from './Practise.jsx'

// import Landingpage  from './Landingpage/Landingpage'

function App() {
  return (
    <div className="">
      <Routing/>
      {/* <Practise/> */}
    </div>
  );
}

export default App;
