import React from 'react'
import { Route, Routes } from 'react-router-dom'
import Landingpage from './Landingpage/Landingpage'
import AboutUs from './AboutUspage/AboutUs'
import Contact from './ContactPage/Contact'
import Career from './CareerPage/Career'
import Service from './Servicepage/Service'
import Page from './Pagenotfound/Page'



const Routing = () => {
  return (
    <div>
      <Routes>
        <Route
          path="/"
          element={<Landingpage />}
        />
        <Route
          path="/AboutUs"
          element={<AboutUs />}
        />
        <Route
          path="/Contact"
          element={<Contact />}
        />
         <Route
          path="/Career"
          element={<Career />}
        />
        <Route
          path="/Service"
          element={<Service />}
        />

        <Route path='*' element={<Page/>}/>
        </Routes>
    </div>
  )
}

export default Routing
