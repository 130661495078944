import React, { useState } from "react";
import "./Page.css";
import logo from "../resources/Unairesync_White_logo.svg";
import erroe from "../resources/untitled.png";
import { useNavigate } from "react-router-dom";
import hamberger from "../resources/Hamburger.svg";
import cross from "../resources/cross.svg";
import x from "../resources/X_corp.svg";
import facebook from "../resources/Facebook.svg";
import Youtube from "../resources/Youtube.svg";
import instagram from "../resources/Instagram.svg";
import pineinterest from "../resources/Pintrest.svg";
import linkedin from "../resources/linkedin.svg";
import watapp from "../resources/Whatsapp.svg"

function Page() {
  const [toogle, settoggle] = useState(false);
  const navigate = useNavigate();


  return (
    <div>
      {toogle === false ? (
        <>
          <div className="container-fluid back">
            <div className="container ">
              <div className="row ">
                <div
                  className="col-lg-6  mt-4 mb-4 col-9 ps-5 justify-content-center"
                  style={{ cursor: "pointer" }}
                >
                  <img
                    src={logo}
                    alt=""
                    className="w-25 "
                    onClick={() => {
                      navigate("/");
                    }}
                  />
                </div>

                <div className="col-lg-6   col-2 pe-5   d-flex justify-content-end ">
                  <div className="row mt-3 ">
                    <div className="col-12  d-none d-lg-block">
                      <ul className="list-inline">
                        <li
                          className="list-inline-item p-2 pe-5  fs-6"
                          style={{ cursor: "pointer", color: "white" }}
                          onClick={() => {
                            navigate("/AboutUs");
                          }}
                        >
                          About Us
                        </li>
                        <li
                          className="list-inline-item p-2 fs-6 pe-5"
                          style={{ cursor: "pointer", color: "white" }}
                          onClick={() => {
                            navigate("/Service");
                          }}
                        >
                          Service
                        </li>
                        <li
                          className="list-inline-item p-2 fs-6 pe-5"
                          style={{ cursor: "pointer", color: "white" }}
                          onClick={() => {
                            navigate("/Career");
                          }}
                        >
                          Career
                        </li>
                        <li
                          className="list-inline-item p-2 fs-6 pe-5"
                          style={{ cursor: "pointer", color: "white" }}
                          onClick={() => {
                            navigate("/Contact");
                          }}
                        >
                          Contact Us
                        </li>
                      </ul>
                    </div>
                    <div className="col d-lg-none">
                      <img
                        src={hamberger}
                        alt=""
                        onClick={(e) => settoggle(!toogle)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        ""
      )}
      {toogle === true ? (
        <>
          <div className="container-fluid mobile border">
            <div className="container  ">
              <div className="row mt-5">
                <div className="col-lg-12">
                  <div className="row">
                    <div className="col-8  text-start ps-5"></div>
                    <div className="col-4">
                      <img
                        src={cross}
                        alt=""
                        onClick={(e) => settoggle(!toogle)}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="row mt-5">
                <div className="col-11 ">
                  <ul
                    className="fw-bold mt-5"
                    style={{
                      listStyle: "none",
                      textAlign: "center",
                      color: "white",
                    }}
                  >
                    <li
                      className=" p-3  fs-5"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        navigate("/AboutUs");
                      }}
                    >
                      About Us
                    </li>
                    <li
                      className=" p-3 fs-5"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        navigate("/Service");
                      }}
                    >
                      Services
                    </li>
                    <li
                      className="p-43 fs-5"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        navigate("/Career");
                      }}
                    >
                      Career
                    </li>
                    <li
                      className=" p-3 fs-5"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        navigate("/Contact");
                      }}
                    >
                      Contact Us
                    </li>
                  </ul>
                </div>
              </div>

              <div className="row mt-4 text-center">
                <div className="col-12">
                  <div className=" ">
                    <img src={logo} className="w-50" alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        ""
      )}

      {toogle === false ? (
        <>
          <div className="container-fluid">
            <div className="row">
              <div className="col-12 pageerror">
                <img
                  src={erroe}
                  style={{ width: "100%", height: "100%" }}
                  alt=""
                />
              </div>
            </div>
          </div>

          <footer>
            <div className="container-fluid foot ">
              <div className="container">
              <div className="row ">
                <div className="col-sm-7   mt-5">
                  <div className=" ps-sm-5 col-md-7 text-sm-start text-center" onClick={()=>{
                    navigate("/")
                  }} style={{cursor:"pointer"}}>
                    <img src={logo} alt="" className="  w-25" />
                  </div>
                </div>

                <div className="col-sm-5 col-md-5   justify-content-center">
                  <div className="row mt-5">
                    <div className="col-12  text-center  ">
                      <ul
                        className=" d-sm-flex ps-sm-5  "
                        style={{ listStyle: "none" }}
                      >
                        <li
                          className="  p-2    fs-5"
                          style={{ cursor: "pointer", color: "white" }}
                          onClick={()=>{
                            navigate("/AboutUs")
                          }}
                        >
                          About Us
                        </li>
                        <li
                          className=" p-2 fs-5"
                          style={{ cursor: "pointer", color: "white" }}
                          onClick={()=>{
                            navigate("/Service")
                          }}
                        >
                          Service
                        </li>
                        <li
                          className=" p-2 fs-5"
                          style={{ cursor: "pointer", color: "white" }}
                          onClick={()=>{
                            navigate("/Career")
                          }}
                        >
                          Career
                        </li>
                        <li
                          className=" p-2 fs-5"
                          style={{ cursor: "pointer", color: "white" }}
                          onClick={() => {
                            navigate("/Contact");
                          }}
                        >
                          Contact Us
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>

                <div className=" d-flex justify-content-center align-items-center">
                  <div className="pe-4">
                    <a
                      href="https://x.com/UnaireSync"
                      className="link"
                      target="blank"
                    >
                      <img src={x} alt="" />
                    </a>
                  </div>
                  <div className="pe-4">
                    <a
                      href="https://www.facebook.com/unairesync"
                      className="link"
                      target="blank"
                    >
                      <img src={facebook} alt="" />
                    </a>
                  </div>
                  <div className="pe-4">
                    <a
                      href="https://www.instagram.com/unairesync_itsolution/"
                      className="link"
                      target="blank"
                    >
                      <img src={instagram} alt="" />
                    </a>
                  </div>
                  <div className="pe-4">
                    <a
                      href="https://in.pinterest.com/unairesync/"
                      className="link"
                      target="blank"
                    >
                      <img src={pineinterest} alt="" />
                    </a>
                  </div>
                  <div className="pe-4">
                    <a
                      href="https://www.youtube.com/@UnaireSync"
                      className="link"
                      target="blank"
                    >
                      <img src={Youtube} alt="" />
                    </a>
                  </div>
                  <div className="pe-4">
                    <a
                      href="https://in.linkedin.com/company/unaire-sync-it-solutions"
                      className="link"
                      target="blank"
                    >
                      <img src={linkedin} alt="" />
                    </a>
                  </div>
                  <div className="pe-4">
                      <a
                        href="https://wa.me/917742691107?text=urlencodedtext"
                        className="link "
                        target="blank"
                      >
                        <img src={watapp} alt="" className="w-75"  />
                      </a>
                    </div>
                </div>
              </div>

              <div className="row mt-3">
                <div className="col-sm-12 text-center">
                  <h5 className="fs-6" style={{ color: "white" }}>
                    &#169;UnaireSyncITSolution All Rights Reserved 2024
                  </h5>
                </div>
              </div>
              </div>
            </div>
          </footer>
        </>
      ) : (
        ""
      )}
    </div>
  );
}

export default Page;
