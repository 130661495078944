import React from "react";
import { useEffect, useState } from "react";
import logo from "../resources/Unairesync_White_logo.svg";
import "./Service.css";
import web from "../resources/grey_Website_developement.svg";
import sales from "../resources/grey_Mobile_app_developement.svg";
import Mobile from "../resources/grey_Salesforce.svg";
import Customer from "../resources/grey_Software_Developemtn.svg";
import dm from "../resources/grey_Graphic_Design.svg";
import hamberger from "../resources/Hamburger.svg";
import cross from "../resources/cross.svg";
import { useNavigate } from "react-router-dom";
import x from "../resources/X_corp.svg";
import facebook from "../resources/Facebook.svg";
import Youtube from "../resources/Youtube.svg";
import instagram from "../resources/Instagram.svg";
import pineinterest from "../resources/Pintrest.svg";
import linkedin from "../resources/linkedin.svg";
import watapp from "../resources/Whatsapp.svg"

function Service() {
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const UPTOP = (path) => {
    navigate(path);
    scrollToTop();
  };

  

  const navigate = useNavigate();
  const [currentIndex, setCurrentIndex] = useState(0);


  const items = [
    {
      text: "Web Application",
      color: "#BEEFFF",
      image: web,
      content:
        " Our expertise in website development allows us to craft responsive and dynamic websites that captivate audiences and drive engagement. We ensure that your online presence reflects your brand's identity while delivering an exceptional user experience. From e-commerce platforms to corporate websites, our developers use cutting-edge tools and frameworks to build sites that are fast, secure, and mobile-friendly, ensuring your business stands out in the digital landscape.",
    },
    {
      text: "Mobile Application",
      color: "#BEEFFF",
      image: sales,
      content:
        " In today's mobile-first world, having a standout app is crucial. We empower your business with intuitive and feature-rich mobile applications tailored to your target audience. Whether it's iOS, Android, or cross-platform development, our apps are designed to excel in the competitive market. Our comprehensive services include UI/UX design, backend development, API integration, and ongoing support and maintenance, ensuring your app remains at the forefront of innovation.",
    },
    {
      text: "SalesForce Services",
      color: "#BEEFFF",
      image: Mobile,
      content:
        " Efficient sales and customer relationship management are vital for business success. Our Salesforce integration services streamline your sales processes and enhance customer relationship management. We customize Salesforce to align with your business objectives, boosting productivity and driving growth. Our end-to-end services include consultation, implementation, customization, and ongoing support, ensuring you get the most out of your Salesforce investment.",
    },
    {
      text: "Customer Software",
      color: "#BEEFFF",
      image: Customer,
      content:
        "For businesses with unique requirements, our custom software development services provide bespoke solutions tailored to your specific needs. We develop applications that integrate seamlessly with your existing systems, enhancing operational efficiency and addressing your unique business challenges. From enterprise software to specialized tools, we deliver solutions that support your business growth and streamline operations.",
    },
    {
      text: "Digital Marketing",
      color: "#BEEFFF",
      image: dm,
      content:
        " Our digital marketing experts craft data-driven strategies to boost your online presence, drive traffic, and maximize conversions. We cover all aspects of digital marketing, including SEO (Search Engine Optimization), PPC (Pay-Per-Click) advertising, social media marketing, and content creation. Additionally, we offer email marketing, influencer marketing, and conversion rate optimization, ensuring a holistic approach to your digital growth. Our goal is to make your brand more visible and engaging to your audience, leading to sustained business success.",
    },
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      const newIndex = currentIndex === items.length - 1 ? 0 : currentIndex + 1;
      setCurrentIndex(newIndex);
    }, 5000);

    return () => clearInterval(interval);
  }, [currentIndex, items.length]);

  const [toogle, settoggle] = useState(false);

  return (
    <div>
      {toogle === false ? (
        <>
          <div className="container-fluid back">
            <div className="container ">
              <div className="row ">
                <div
                  className="col-lg-6  mt-4 mb-4 col-9  ps-5 justify-content-center"
                  style={{ cursor: "pointer" }}
                >
                  <img
                    src={logo}
                    alt=""
                    className="w-25 "
                    onClick={() => {
                      navigate("/");
                    }}
                  />
                </div>

                <div className="col-lg-6   col-2 pe-5   d-flex justify-content-end ">
                  <div className="row mt-3 ">
                    <div className="col-12  d-none d-lg-block">
                      <ul className="list-inline">
                        <li
                          className="list-inline-item p-2 pe-5  fs-6"
                          style={{ cursor: "pointer", color: "white" }}
                          onClick={() => {
                            navigate("/AboutUs");
                          }}
                        >
                          About Us
                        </li>
                        <li
                          className="list-inline-item p-2 fs-6 pe-5"
                          style={{ cursor: "pointer", color: "white" }}
                        >
                          Service
                        </li>
                        <li
                          className="list-inline-item p-2 fs-6 pe-5"
                          style={{ cursor: "pointer", color: "white" }}
                          onClick={() => {
                            navigate("/Career");
                          }}
                        >
                          Career
                        </li>
                        <li
                          className="list-inline-item p-2 fs-6 pe-5"
                          style={{ cursor: "pointer", color: "white" }}
                          onClick={() => {
                            navigate("/Contact");
                          }}
                        >
                          Contact Us
                        </li>
                      </ul>
                    </div>
                    <div className="col d-lg-none">
                      <img
                        src={hamberger}
                        alt=""
                        onClick={(e) => settoggle(!toogle)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        ""
      )}
      {toogle === true ? (
        <>
          <div className="container-fluid mobile border">
            <div className="container  ">
              <div className="row mt-5">
                <div className="col-lg-12">
                  <div className="row">
                    <div className="col-8  text-start ps-5"></div>
                    <div className="col-4">
                      <img
                        src={cross}
                        alt=""
                        onClick={(e) => settoggle(!toogle)}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="row mt-5">
                <div className="col-11 ">
                  <ul
                    className=" fw-bold mt-5"
                    style={{
                      listStyle: "none",
                      textAlign: "center",
                      color: "white",
                    }}
                  >
                    <li
                      className=" p-3  fs-5"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        navigate("/AboutUs");
                      }}
                    >
                      About Us
                    </li>
                    <li className=" p-3 fs-5" style={{ cursor: "pointer" }}>
                      Services
                    </li>
                    <li
                      className="p-3 fs-5"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        navigate("/Career");
                      }}
                    >
                      Career
                    </li>
                    <li
                      className=" p-3 fs-5"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        navigate("/Contact");
                      }}
                    >
                      Contact Us
                    </li>
                  </ul>
                </div>
              </div>

              <div className="row mt-4 text-center">
                <div className="col-12">
                  <div className=" ">
                    <img src={logo} className="w-50" alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        ""
      )}

      {toogle === false ? (
        <>
          <div className="container mt-5  mb-5">
            <div className="row ">
              <div className="col-12 ">
                <div className="mb-4">
                  <h1 className="fs-1 p-2">Services</h1>
                </div>
                <p className="mb-5  fs-5 p-2" style={{ textAlign: "justify" }}>
                  At Unaire Sync IT Solutions , we pride ourselves on providing
                  a broad spectrum of comprehensive digital solutions tailored
                  to meet the unique needs of businesses across various
                  industries . Our diverse range of services ensures that we can
                  address every aspect of your digital transformation journey
                  with precision and expertise .
                </p>
              </div>
            </div>
          </div>

          {/* second section */}
          <div className="slider-container">
            <div className="container">
              <div className="row align-items-center justify-content-center">
                <div className="col-8 col-md-8 text-center">
                  <div className="mx-auto">
                    <img
                      className="w-50"
                      src={items[currentIndex].image}
                      alt={`Slide ${currentIndex}`}
                    />
                  </div>
                </div>
                <div className="col-12 col-md-4 text-center">
                  {items.map((item, index) => (
                    <div
                      key={index}
                      className="text-item p-3 fs-lg-4 fs-5 fw-bold"
                      style={{
                        backgroundColor:
                          currentIndex === index ? item.color : "transparent",
                      }}
                    >
                      {item.text}
                    </div>
                  ))}
                </div>
              </div>
              <div className="row mt-5 py-5 mb-5 ">
                <div className="col-12 text-center">
                  <div
                    className="content-item p-3 fs-lg-4 fs-5 "
                    style={{ textAlign: "justify" }}
                  >
                    {items[currentIndex].content}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <footer>
            <div className="container-fluid foot ">
              <div className="container">
                <div className="row ">
                  <div className="col-sm-7   mt-5">
                    <div
                      className=" ps-sm-5 ps-3 col-md-7 text-sm-start text-center"
                     
                    >
                      <img src={logo} alt="" className=" w-50"  onClick={() => UPTOP("/")}
                      style={{ cursor: "pointer" }}/>
                    </div>
                  </div>

                  <div className="col-sm-5  col-md-5  justify-content-center">
                    <div className="row mt-5">
                      <div className="col-12  text-center  ">
                        <ul
                          className=" d-sm-flex ps-sm-5  "
                          style={{ listStyle: "none" }}
                        >
                          <li
                            className="  pe-4 p-2  fs-5"
                            style={{ cursor: "pointer", color: "white" }}
                            onClick={() => UPTOP("/AboutUs")}
                          >
                            About Us
                          </li>
                          <li
                            className=" pe-4 p-2  fs-5"
                            style={{ cursor: "pointer", color: "white" }}
                          >
                            Service
                          </li>
                          <li
                            className=" pe-4 p-2  fs-5"
                            style={{ cursor: "pointer", color: "white" }}
                            onClick={() => UPTOP("/Career")}
                          >
                            Career
                          </li>
                          <li
                            className=" p-2 pe-4 fs-5"
                            style={{ cursor: "pointer", color: "white" }}
                            onClick={() => UPTOP("/Contact")}
                          >
                            Contact Us
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>

                  <div className=" d-flex justify-content-center align-items-center">
                    <div className="pe-4">
                      <a
                        href="https://x.com/UnaireSync"
                        className="link"
                        target="blank"
                      >
                        <img src={x} alt="" />
                      </a>
                    </div>
                    <div className="pe-4">
                      <a
                        href="https://www.facebook.com/unairesync"
                        className="link"
                        target="blank"
                      >
                        <img src={facebook} alt="" />
                      </a>
                    </div>
                    <div className="pe-4">
                      <a
                        href="https://www.instagram.com/unairesync_itsolution/"
                        className="link"
                        target="blank"
                      >
                        <img src={instagram} alt="" />
                      </a>
                    </div>
                    <div className="pe-4">
                      <a
                        href="https://in.pinterest.com/unairesync/"
                        className="link"
                        target="blank"
                      >
                        <img src={pineinterest} alt="" />
                      </a>
                    </div>
                    <div className="pe-4">
                      <a
                        href="https://www.youtube.com/@UnaireSync"
                        className="link"
                        target="blank"
                      >
                        <img src={Youtube} alt="" />
                      </a>
                    </div>
                    <div className="pe-4">
                      <a
                        href="https://in.linkedin.com/company/unaire-sync-it-solutions"
                        className="link"
                        target="blank"
                      >
                        <img src={linkedin} alt="" />
                      </a>
                    </div>
                    <div className="pe-4">
                      <a
                        href="https://wa.me/917742691107?text=urlencodedtext"
                        className="link "
                        target="blank"
                      >
                        <img src={watapp} alt="" className="w-75"  />
                      </a>
                    </div>
                  </div>
                </div>

                <div className="row mt-3">
                  <div className="col-sm-12 text-center">
                    <h5 className="fs-6" style={{ color: "white" }}>
                      &#169;UnaireSyncITSolution All Rights Reserved 2024
                    </h5>
                  </div>
                </div>
              </div>
            </div>
          </footer>
        </>
      ) : (
        ""
      )}
    </div>
  );
}

export default Service;
