import React from "react";
import { useState } from "react";
import logo from "../resources/Unairesync_White_logo.svg";
import "./Contact.css";
import hamberger from "../resources/Hamburger.svg";
import cross from "../resources/cross.svg";
import { useNavigate } from "react-router-dom";
import x from "../resources/X_corp.svg";
import facebook from "../resources/Facebook.svg";
import Youtube from "../resources/Youtube.svg";
import instagram from "../resources/Instagram.svg";
import pineinterest from "../resources/Pintrest.svg";
import linkedin from "../resources/linkedin.svg";
import watapp from "../resources/Whatsapp.svg"


function Contact() {


  const navigate = useNavigate();
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const UPTOP = (path) => {
    navigate(path);
    scrollToTop();
  };

  
  const [fname, setname] = useState("");
  const [email, setEmail] = useState("");
  const [phoneno, setphoneno] = useState("");
  const [discription, setdiscription] = useState("");
  const [service, setServices] = useState("");

  const [name, setnname] = useState("");
  const handleNumericOnly = (event) => {
    const { value } = event.target;

    const isnot = /^[0-9]*$/.test(event.key);
    const isnot2 = /^$/.test(event.key);
    if (isnot || isnot2) {
      setnname(value);
    } else {
      event.preventDefault();
    }
  };

  const mailvalid2 = (variablename) => {
    const checkmail = /^([a-z0-9._]+@[a-z]+\.[a-z]{2,4})$/;
    if (variablename.match(checkmail)) {
      //email validate true
      console.log("mail valid");
    } else {
      //email invalid
      console.log("Invalid mail");
    }
  };

  const handleAlphaOnly = (event) => {
    const { value } = event.target;
    const isAlpha = /^[a-zA-Z]*$/.test(event.key);
    const isAllowedKey = [" ", "Backspace"].includes(event.key);

    if (!isAlpha && !isAllowedKey) {
      event.preventDefault();
    }
  };

  const [toogle, settoggle] = useState(false);

  const mailsend = (e) => {
    e.preventDefault();
    mailvalid2(email);

    const data = {
      fname: fname,
      number: phoneno,
      discription: discription,
      mail: email,
      service: service,
    };

    const url = "https://backend-company-n8wo.onrender.com/sendmail";
    try {
      fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      }).then((dataa) => {
        if (dataa.ok === true) {
          setname("");
          // console.log(dataa,'data999999999999999999999999999999999999999999999999999999999999999999999999999999999');

          setEmail("");
          setdiscription("");
          setphoneno("");
          setServices("");
        }
      });
    } catch {}
  };

  return (
    <div>
      {toogle === false ? (
        <>
          <div className="container-fluid back">
            <div className="container ">
              <div className="row ">
                <div
                  className="col-lg-6  mt-4 mb-4 col-9 ps-5 justify-content-center"
                  style={{ cursor: "pointer" }}
                >
                  <img
                    src={logo}
                    alt=""
                    className="w-25 "
                    onClick={() => {
                      navigate("/");
                    }}
                  />
                </div>

                <div className="col-lg-6   col-2 pe-5   d-flex justify-content-end ">
                  <div className="row mt-3 ">
                    <div className="col-12  d-none d-lg-block">
                      <ul className="list-inline">
                        <li
                          className="list-inline-item p-2 pe-5  fs-6"
                          style={{ cursor: "pointer", color: "white" }}
                          onClick={() => {
                            navigate("/AboutUs");
                          }}
                        >
                          About Us
                        </li>
                        <li
                          className="list-inline-item p-2 fs-6 pe-5"
                          style={{ cursor: "pointer", color: "white" }}
                          onClick={() => {
                            navigate("/Service");
                          }}
                        >
                          Service
                        </li>
                        <li
                          className="list-inline-item p-2 fs-6 pe-5"
                          style={{ cursor: "pointer", color: "white" }}
                          onClick={() => {
                            navigate("/Career");
                          }}
                        >
                          Career
                        </li>
                        <li
                          className="list-inline-item p-2 fs-6 pe-5"
                          style={{ cursor: "pointer", color: "white" }}
                        >
                          Contact Us
                        </li>
                      </ul>
                    </div>
                    <div className="col d-lg-none">
                      <img
                        src={hamberger}
                        alt=""
                        onClick={(e) => settoggle(!toogle)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        ""
      )}
      {toogle === true ? (
        <>
          <div className="container-fluid mobile border">
            <div className="container  ">
              <div className="row mt-5">
                <div className="col-lg-12">
                  <div className="row">
                    <div className="col-8  text-start ps-5"></div>
                    <div className="col-4">
                      <img
                        src={cross}
                        alt=""
                        onClick={(e) => settoggle(!toogle)}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="row mt-5">
                <div className="col-11 ">
                  <ul
                    className="fw-bold mt-5"
                    style={{
                      listStyle: "none",
                      textAlign: "center",
                      color: "white",
                    }}
                  >
                    <li
                      className=" p-4  fs-5"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        navigate("/AboutUs");
                      }}
                    >
                      About Us
                    </li>
                    <li
                      className=" p-4 fs-5"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        navigate("/Service");
                      }}
                    >
                      Services
                    </li>
                    <li
                      className="p-4 fs-5"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        navigate("/Career");
                      }}
                    >
                      Career
                    </li>
                    <li className=" p-4 fs-5" style={{ cursor: "pointer" }}>
                      Contact Us
                    </li>
                  </ul>
                </div>
              </div>

              <div className="row mt-4 text-center">
                <div className="col-12">
                  <div className=" ">
                    <img src={logo} className="w-50" alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        ""
      )}

      {toogle === false ? (
        <>
          <div className="container-fluid contectsection2 mb-5 mt-5 py-5 ">
            <form action="">
              <div className="row position-relative  ">
                <div className="col-10 col-md-8 col-lg-6 contact mx-auto p-5 mt-5 py-5">
                  <div className="row p-3">
                    <h1 style={{ color: "white" }} className="fs-1">
                      Contact Us
                    </h1>
                  </div>
                  <div className="row">
                    <div className="col-md-6 p-3">
                      <select className="form-select " aria-label="">
                        <option selected value={service}>
                          Service Type
                        </option>
                        <option value="General">General</option>
                        <option value="Software Development">
                          Software Development
                        </option>
                        <option value="Website Development">
                          Website Development
                        </option>
                        <option value="Salesforce">salesforce</option>
                        <option value="Custom Software ">
                          Custom Software
                        </option>
                        <option value="ERP">ERP</option>
                        <option value="CRM">CRM</option>
                        <option value="Digital Marketing">
                          Digital Marketing
                        </option>
                        <option value="Graphic Designing">
                          Graphic Designing
                        </option>
                        <option value="Product Dev">Product Dev</option>
                        <option value="Brand Building">Brand Building</option>
                        <option value="SEO (Search Engine Optemisation)">
                          SEO (Search Engine Optemisation)
                        </option>
                        <option value="WordPress Website">
                          WordPress Website
                        </option>
                        <option value="Shopify Website & Store Setup">
                          Shopify Website & Store Setup
                        </option>
                      </select>
                    </div>
                    <div className="col-md-6 p-3">
                      <input
                        type="text"
                        className="form-control transparent-input"
                        name=""
                        id=""
                        placeholder="Enter Phone Number"
                        onKeyPress={handleNumericOnly}
                        maxLength={10}
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-6 ">
                      <div className="row">
                        <div className="col p-3 ">
                          <input
                            type="text"
                            className="form-control transparent-input"
                            name=""
                            id=""
                            value={fname}
                            onChange={(e) => setname(e.target.value)}
                            placeholder="Enter Full Name"
                            onKeyPress={handleAlphaOnly}
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col p-3">
                          <input
                            type="text"
                            className="form-control transparent-input"
                            name=""
                            // value={email}
                            id=""
                            placeholder="Enter Mail Address"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="col-md-6 p-3">
                      <textarea
                        name=""
                        className="form-control h-100 transparent-input "
                        id=""
                        placeholder="Description"
                        value={discription}
                        onChange={(e) => {
                          setdiscription(e.target.value);
                        }}
                      ></textarea>
                    </div>
                  </div>

                  <div className="mt-md-5 mt-3 text-center">
                    <button className="btn">Submit</button>
                  </div>
                </div>
              </div>
            </form>
          </div>

          <footer>
            <div className="container-fluid foot ">
              <div className="container">
              <div className="row ">
                <div className="col-sm-7   mt-5">
                  <div className=" ps-sm-5  col-md-7 text-sm-start text-center" >
                    <img src={logo} alt="" className="  w-50" onClick={() => UPTOP("/")} style={{cursor:"pointer"}}/>
                  </div>
                </div>

                <div className="col-sm-5 col-md-5  justify-content-center">
                  <div className="row mt-5">
                    <div className="col-12  text-center  ">
                      <ul
                        className=" d-sm-flex ps-sm-5  "
                        style={{ listStyle: "none" }}
                      >
                        <li
                          className="  pe-4   p-2  fs-5"
                          style={{ cursor: "pointer", color: "white" }}
                          onClick={() => UPTOP("/AboutUs")}
                        >
                          About Us
                        </li>
                        <li
                          className=" pe-4 p-2  fs-5"
                          style={{ cursor: "pointer", color: "white" }}
                          onClick={() => UPTOP("/Service")}
                        >
                          Service
                        </li>
                        <li
                          className=" pe-4 p-2  fs-5"
                          style={{ cursor: "pointer", color: "white" }}
                          onClick={() => UPTOP("/Career")}
                        >
                          Career
                        </li>
                        <li
                          className="    pe-4  p-2  fs-5"
                          style={{ cursor: "pointer", color: "white" }}
                          onClick={() => UPTOP("/Contact")}
                        >
                          Contact Us
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>

                <div className=" d-flex justify-content-center align-items-center">
                  <div className="pe-4">
                    <a
                      href="https://x.com/UnaireSync"
                      className="link"
                      target="blank"
                    >
                      <img src={x} alt="" />
                    </a>
                  </div>
                  <div className="pe-4">
                    <a
                      href="https://www.facebook.com/unairesync"
                      className="link"
                      target="blank"
                    >
                      <img src={facebook} alt="" />
                    </a>
                  </div>
                  <div className="pe-4">
                    <a
                      href="https://www.instagram.com/unairesync_itsolution/"
                      className="link"
                      target="blank"
                    >
                      <img src={instagram} alt="" />
                    </a>
                  </div>
                  <div className="pe-4">
                    <a
                      href="https://in.pinterest.com/unairesync/"
                      className="link"
                      target="blank"
                    >
                      <img src={pineinterest} alt="" />
                    </a>
                  </div>
                  <div className="pe-4">
                    <a
                      href="https://www.youtube.com/@UnaireSync"
                      className="link"
                      target="blank"
                    >
                      <img src={Youtube} alt="" />
                    </a>
                  </div>
                  <div className="pe-4">
                    <a
                      href="https://in.linkedin.com/company/unaire-sync-it-solutions"
                      className="link"
                      target="blank"
                    >
                      <img src={linkedin} alt="" />
                    </a>
                  </div>
                  <div className="pe-4">
                      <a
                        href="https://wa.me/917742691107?text=urlencodedtext"
                        className="link "
                        target="blank"
                      >
                        <img src={watapp} alt="" className="w-75"  />
                      </a>
                    </div>
                </div>
              </div>

              <div className="row mt-3">
                <div className="col-sm-12 text-center">
                  <h5 className="fs-6" style={{ color: "white" }}>
                    &#169;UnaireSyncITSolution All Rights Reserved 2024
                  </h5>
                </div>
              </div>
              </div>
            </div>
          </footer>
        </>
      ) : (
        ""
      )}
    </div>
  );
}

export default Contact;
