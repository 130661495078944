import React, { useState} from "react";
import logo from "../resources/Unairesync_White_logo.svg";
import "./Career.css";
import chaire from "../resources/ian-taylor-FSMWNt54iKU-unsplash 2.png";
import hamberger from "../resources/Hamburger.svg";
import cross from "../resources/cross.svg";
import { useNavigate } from "react-router-dom";
import x from "../resources/X_corp.svg";
import facebook from "../resources/Facebook.svg";
import Youtube from "../resources/Youtube.svg";
import instagram from "../resources/Instagram.svg";
import pineinterest from "../resources/Pintrest.svg";
import linkedin from "../resources/linkedin.svg";
import watapp from "../resources/Whatsapp.svg"

function Career() {
  const [toogle, settoggle] = useState(false);
  const navigate = useNavigate();
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const UPTOP = (path) => {
    navigate(path);
    scrollToTop();
  };

  const [name, setname] = useState("");
  const handleNumericOnly = (event) => {
    const { value } = event.target;

    const isnot = /^[0-9]*$/.test(event.key);
    const isnot2 = /^$/.test(event.key);
    if (isnot || isnot2) {
      setname(value);
    } else {
      event.preventDefault();
    }
  };

  // const mailvalid2 = (variablename) => {
  //   const checkmail = /^([a-z0-9._]+@[a-z]+\.[a-z]{2,4})$/;
  //   if (variablename.match(checkmail)) {
  //     //email validate true
  //     console.log("helloooo");
  //   } else {
  //     //email invalid
  //     console.log("hiii");
  //   }
  // };

  const handleAlphaOnly = (event) => {
    const { value } = event.target;
    const isAlpha = /^[a-zA-Z]*$/.test(event.key);
    const isAllowedKey = [" ", "Backspace"].includes(event.key);

    if (!isAlpha && !isAllowedKey) {
      event.preventDefault();
    }
  };

  return (
    <>
      {toogle === false ? (
        <>
          <div className="container-fluid back">
            <div className="container ">
              <div className="row ">
                <div
                  className="col-lg-6  mt-4 mb-4 col-9 ps-5 justify-content-center"
                  style={{ cursor: "pointer" }}
                >
                  <img
                    src={logo}
                    alt=""
                    className="w-25 "
                    onClick={() => {
                      navigate("/");
                    }}
                  />
                </div>

                <div className="col-lg-6   col-2 pe-5   d-flex justify-content-end ">
                  <div className="row mt-3 ">
                    <div className="col-12  d-none d-lg-block">
                      <ul className="list-inline">
                        <li
                          className="list-inline-item p-2 pe-5  fs-6"
                          style={{ cursor: "pointer", color: "white" }}
                          onClick={() => {
                            navigate("/AboutUs");
                          }}
                        >
                          About Us
                        </li>
                        <li
                          className="list-inline-item p-2 fs-6 pe-5"
                          style={{ cursor: "pointer", color: "white" }}
                          onClick={() => {
                            navigate("/Service");
                          }}
                        >
                          Service
                        </li>
                        <li
                          className="list-inline-item p-2 fs-6 pe-5"
                          style={{ cursor: "pointer", color: "white" }}
                        >
                          Career
                        </li>
                        <li
                          className="list-inline-item p-2 fs-6 pe-5"
                          style={{ cursor: "pointer", color: "white" }}
                          onClick={() => {
                            navigate("/Contact");
                          }}
                        >
                          Contact Us
                        </li>
                      </ul>
                    </div>
                    <div className="col d-lg-none">
                      <img
                        src={hamberger}
                        alt=""
                        onClick={(e) => settoggle(!toogle)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        ""
      )}
      {toogle === true ? (
        <>
          <div className="container-fluid mobile border">
            <div className="container  ">
              <div className="row mt-5">
                <div className="col-lg-12">
                  <div className="row">
                    <div className="col-8  text-start ps-5"></div>
                    <div className="col-4">
                      <img
                        src={cross}
                        alt=""
                        onClick={(e) => settoggle(!toogle)}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="row mt-5">
                <div className="col-11 ">
                  <ul
                    className="mt-5"
                    style={{
                      listStyle: "none",
                      textAlign: "center",
                      color: "white",
                    }}
                  >
                    <li
                      className=" p-3 fw-bold fs-5"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        navigate("/AboutUs");
                      }}
                    >
                      About Us
                    </li>
                    <li
                      className=" p-3 fw-bold fs-5"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        navigate("/Service");
                      }}
                    >
                      Services
                    </li>
                    <li className="p-3 fs-5 fw-bold" style={{ cursor: "pointer" }}>
                      Career
                    </li>
                    <li
                      className=" p-3 fs-5 fw-bold"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        navigate("/Contact");
                      }}
                    >
                      Contact Us
                    </li>
                    
                  </ul>
                </div>
              </div>

              <div className="row mt-4 text-center">
                <div className="col-12">
                  <div className=" ">
                    <img src={logo} className="w-50" alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        ""
      )}

      {toogle === false ? (
        <>
          <div className="container-fluid sectioncareer ">
            <div className="container ">
              <div className="row">
                <div className="col-12 mt-5 py-5">
                  <div className="mb-5 mt-5">
                    <h1 className="fs-1" style={{ color: "white" }}>
                      Welcome
                    </h1>
                  </div>

                  <div className="">
                    <p style={{ color: "white", textAlign:"justify" }} className="fs-5">
                      Welcome to Unaire Sync IT solutions Pvt Ltd, where growth
                      is at the heart of everything we do. We empower our team
                      members to chart their own paths while providing
                      unwavering support and resources. Together, we're on a
                      mission to revolutionize the digital landscape, crafting
                      innovative software solutions that redefine user
                      experiences. With a culture of collaboration and
                      empowerment, we're shaping the future of technology, step
                      by step, with each innovation we create.
                    </p>
                  </div>
                </div>
              </div>

              <div className="row mt-4 ">
                <div className="col-12 mb-5">
                  <div className="">
                    <h1 className="fs-1" style={{ color: "white" }}>
                      Available Position{" "}
                      <span style={{ color: "#64DAF3" }}>...</span>
                    </h1>
                  </div>
                </div>

                <div className="row m-1 mb-5 mt-5 positionborder">
                  <div className="col-lg-9 col-xl-9">
                    <div className="p-3">
                      <h3 style={{ color: "white" }}>
                        {" "}
                        Graphic Designer & Video Editor
                      </h3>
                    </div>
                    <div className="p-3" style={{ color: "white" ,textAlign:"justify"}}>
                      <p>
                        We are seeking a versatile and talented individual to
                        join our team as a Graphic Designer & Video Editor. The
                        ideal candidate will have a passion for both graphic
                        design and video editing, with the ability to create
                        compelling visual content for various digital platforms.
                      </p>
                    </div>
                  </div>

                  <div className="col-3 p-3 ps-4 p-lg-5">
                    <button className="btn">Coming Soon</button>
                  </div>
                </div>

                <div className="row m-1 positionborder mb-5 ">
                  <div className="col-lg-9 col-xl-9">
                    <div className="p-3">
                      <h3 style={{ color: "white" ,textAlign:"justify"}}>
                        Frontend UI/UX Developer + Web Designer
                      </h3>
                    </div>
                    <div className="p-3">
                      <p style={{ color: "white" }}>
                        We are seeking a talented Frontend UI/UX Developer + Web
                        Designer to join our team. The ideal candidate will be
                        passionate about creating intuitive and visually
                        appealing user interfaces, with a keen eye for detail
                        and a solid understanding of web design & UI/UX
                        principles
                      </p>
                    </div>
                  </div>

                  <div className="col-3 p-3 ps-4 p-lg-5">
                    <button className="btn">Coming Soon</button>
                  </div>
                </div>

                <div className="row m-1 positionborder mb-5 ">
                  <div className="col-lg-9 col-xl-9">
                    <div className="p-3">
                      <h3 style={{ color: "white" }}>Sales Executive</h3>
                    </div>
                    <div className="p-3">
                      <p style={{ color: "white",textAlign:"justify" }}>
                        We are seeking a dynamic and results-driven Sales
                        Executive to join our team at Unaire Sync IT Solutions
                        Pvt. Ltd. The ideal candidate will be responsible for
                        generating leads, building relationships with potential
                        clients, and closing sales to achieve revenue targets.
                        This role requires a self-motivated individual with
                        excellent communication skills and a proven track record
                        in sales.
                      </p>
                    </div>
                  </div>

                  <div className="col-3 p-3 ps-4 p-lg-5">
                    <button className="btn">Coming Soon</button>
                  </div>
                </div>

                <div className="row m-1 positionborder mb-5 ">
                  <div className="col-lg-9 col-xl-9">
                    <div className="p-3">
                      <h3 style={{ color: "white" }}>
                        Business Development Associate
                      </h3>
                    </div>
                    <div className="p-3">
                      <p style={{ color: "white",textAlign:"justify" }}>
                        The Business Development Associate will play a key role
                        in driving the growth of our business by identifying new
                        business opportunities, building and maintaining client
                        relationships, and contributing to the development and
                        execution of strategic initiatives. This position offers
                        an exciting opportunity for someone with strong
                        interpersonal skills, strategic thinking, and a passion
                        for driving business success.
                      </p>
                    </div>
                  </div>

                  <div className="col-3 p-3 ps-4 p-lg-5">
                    <button className="btn">Coming Soon</button>
                  </div>
                </div>

                <div className="row m-1 positionborder mb-5 ">
                  <div className="col-lg-9 col-xl-9">
                    <div className="p-3">
                      <h3 style={{ color: "white" }}>Full Stack Developer</h3>
                    </div>
                    <div className="p-3">
                      <p style={{ color: "white" }}>
                        We are currently seeking a talented Full Stack Developer
                        to join our team. The ideal candidate will be passionate
                        about building scalable and efficient web applications,
                        with a strong foundation in both frontend and backend
                        development technologies. Responsibilities:
                      </p>
                    </div>
                  </div>

                  <div className="col-3 p-3 ps-4 p-lg-5">
                    <button className="btn">Coming Soon</button>
                  </div>
                </div>

                <div className="row m-1 positionborder mb-5 ">
                  <div className="col-lg-9 col-xl-9">
                    <div className="p-3">
                      <h3 style={{ color: "white" }}>Front End Developer</h3>
                    </div>
                    <div className="p-3">
                      <p style={{ color: "white",textAlign:"justify" }}>
                        We are seeking a dynamic and results-driven Sales
                        Executive to join our team at Unaire Sync IT Solutions
                        Pvt. Ltd. The ideal candidate will be responsible for
                        generating leads, building relationships with potential
                        clients, and closing sales to achieve revenue targets.
                        This role requires a self-motivated individual with
                        excellent communication skills and a proven track record
                        in sales.
                      </p>
                    </div>
                  </div>

                  <div className="col-3 p-3 ps-4 p-lg-5">
                    <button className="btn">Coming Soon</button>
                  </div>
                </div>

                <div className="row m-1 positionborder mb-5 ">
                  <div className="col-lg-9 col-xl-9">
                    <div className="p-3">
                      <h3 style={{ color: "white" }}>Back End Developer</h3>
                    </div>
                    <div className="p-3">
                      <p style={{ color: "white",textAlign:"justify" }}>
                        We are seeking a talented Backend Developer to join our
                        team. The ideal candidate will be passionate about
                        developing robust and scalable backend systems, with a
                        focus on performance, security, and maintainability.
                      </p>
                    </div>
                  </div>

                  <div className="col-3 p-3 ps-4 p-lg-5">
                    <button className="btn">Coming Soon</button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="container-fluid ">
            <div className="row mt-5  justify-content-center">
              <div className="col-12 col-lg-6 p-5 my-5 ">
                <form action="">
                  <div className="row justify-content-center  ">
                    <div className="col-12 contact mx-auto  mt-5 py-5">
                      <div className="row p-3 px-5">
                        <h1 style={{ color: "white" }} className="fs-2">
                          Apply to Company
                        </h1>
                      </div>
                      <div className="row px-5">
                        <div className="col-12 col-lg-6 p-3">
                          <input
                            type="text"
                            name=""
                            className="form-control transparent-input"
                            id=""
                            placeholder="Enter Full Name"
                            onKeyPress={handleAlphaOnly}
                          />
                        </div>
                        <div className="col-12 col-lg-6 p-3">
                          <input
                            type="file"
                            className="form-control transparent-input"
                            name=""
                            id=""
                            onKeyPress={handleAlphaOnly}
                            placeholder="Attach Resume or CV"
                          />
                        </div>
                      </div>

                      <div className="row px-5">
                        <div className="col-12 col-lg-6">
                          <div className="row">
                            <div className="col p-3 ">
                              <input
                                type="text"
                                className="form-control transparent-input"
                                name=""
                                id=""
                                placeholder="Enter Mail Address"
                              />
                            </div>
                          </div>
                          <div className="row">
                            <div className="col p-3">
                              <input
                                type="text"
                                className="form-control transparent-input"
                                name=""
                                id=""
                                placeholder="Enter Phone No"
                                maxLength={10}
                                onKeyPress={handleNumericOnly}
                              />
                            </div>
                          </div>
                        </div>

                        <div className="col-12 col-lg-6 p-3">
                          <textarea
                            name=""
                            className="form-control h-100 transparent-input "
                            id=""
                            placeholder="Application Letter"
                          ></textarea>
                        </div>
                      </div>

                      <div className="mt-4 text-center">
                        <button className="btn w-50">
                          <span className="spn2 ">Submit</span>
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
              <div className="d-none m-auto  d-lg-flex col-lg-6 px-5 mx-auto mt-5 ">
                <div className="text-center mt-4">
                  <img
                    src={chaire}
                    alt=""
                    style={{ width: "90%", height: "90%" }}
                  />
                </div>
              </div>
            </div>
          </div>

          <footer>
            <div className="container-fluid foot ">
              <div className="container">
                <div className="row ">
                  <div className="col-sm-7   mt-5">
                    <div className=" ps-sm-5 ps-3 col-md-7 text-sm-start text-center" >
                      <img src={logo} alt="" className="  w-50"  onClick={() => UPTOP("/")} style={{cursor:"pointer"}} />
                    </div>
                  </div>

                  <div className="col-sm-5 col-md-5   justify-content-center">
                    <div className="row mt-5">
                      <div className="col-12  text-center  ">
                        <ul
                          className=" d-sm-flex ps-sm-5  "
                          style={{ listStyle: "none" }}
                        >
                          <li
                            className="  pe-4   p-2  fs-5"
                            style={{ cursor: "pointer", color: "white" }}
                            onClick={() => UPTOP("/AboutUs")}
                          >
                            About Us
                          </li>
                          <li
                            className=" pe-4 p-2  fs-5"
                            style={{ cursor: "pointer", color: "white" }}
                            onClick={() => UPTOP("/Service")}
                          >
                            Service
                          </li>
                          <li
                            className=" pe-4 p-2  fs-5"
                            style={{ cursor: "pointer", color: "white" }}
                          >
                            Career
                          </li>
                          <li
                            className="  p-2 pe-4 fs-5"
                            style={{ cursor: "pointer", color: "white" }}
                            onClick={() => {
                              navigate("/Contact");
                            }}
                          >
                            Contact Us
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>

                  <div className=" d-flex justify-content-center  align-items-center">
                    <div className="pe-4">
                      <a
                        href="https://x.com/UnaireSync"
                        className="link"
                        target="blank"
                      >
                        <img src={x} alt="" />
                      </a>
                    </div>
                    <div className="pe-4">
                      <a
                        href="https://www.facebook.com/unairesync"
                        className="link"
                        target="blank"
                      >
                        <img src={facebook} alt="" />
                      </a>
                    </div>
                    <div className="pe-4">
                      <a
                        href="https://www.instagram.com/unairesync_itsolution/"
                        className="link"
                        target="blank"
                      >
                        <img src={instagram} alt="" />
                      </a>
                    </div>
                    <div className="pe-4">
                      <a
                        href="https://in.pinterest.com/unairesync/"
                        className="link"
                        target="blank"
                      >
                        <img src={pineinterest} alt="" />
                      </a>
                    </div>
                    <div className="pe-4">
                      <a
                        href="https://www.youtube.com/@UnaireSync"
                        className="link"
                        target="blank"
                      >
                        <img src={Youtube} alt="" />
                      </a>
                    </div>
                    <div className="pe-4">
                      <a
                        href="https://in.linkedin.com/company/unaire-sync-it-solutions"
                        className="link"
                        target="blank"
                      >
                        <img src={linkedin} alt="" />
                      </a>
                    </div>
                    <div className="pe-4">
                      <a
                        href="https://wa.me/917742691107?text=urlencodedtext"
                        className="link "
                        target="blank"
                      >
                        <img src={watapp} alt="" className="w-75"  />
                      </a>
                    </div>
                  </div>
                </div>

                <div className="row mt-3">
                  <div className="col-sm-12 text-center">
                    <h5 className="fs-6" style={{ color: "white" }}>
                      &#169;UnaireSyncITSolution All Rights Reserved 2024
                    </h5>
                  </div>
                </div>
              </div>
            </div>
          </footer>
        </>
      ) : (
        ""
      )}
    </>
  );
}

export default Career;
