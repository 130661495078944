import React from "react";
import { useState,useEffect } from "react";
import logo from "../resources/Unairesync_White_logo.svg";
import "./AboutUs.css";
import vision from "../resources/Vision.svg";
import values from "../resources/Value.svg";
import mission from "../resources/Mission.svg";
import photo from "../resources/set.jpg";
import hamberger from "../resources/Hamburger.svg";
import cross from "../resources/cross.svg";
import { useNavigate } from "react-router-dom";
import x from "../resources/X_corp.svg";
import facebook from "../resources/Facebook.svg";
import Youtube from "../resources/Youtube.svg";
import instagram from "../resources/Instagram.svg";
import pineinterest from "../resources/Pintrest.svg";
import linkedin from "../resources/linkedin.svg";
import watapp from "../resources/Whatsapp.svg"

function AboutUs() {
  const [toogle, settoggle] = useState(false);
  const navigate = useNavigate();
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const UPTOP = (path) => {
    navigate(path);
    scrollToTop();
  };
   


  return (
    <div>
      {toogle === false ? (
        <>
          <div className="container-fluid back">
            <div className="container ">
              <div className="row ">
                <div
                  className="col-lg-6  mt-4 mb-4 col-9 ps-5 justify-content-center"
                  style={{ cursor: "pointer" }}
                >
                  <img
                    src={logo}
                    alt=""
                    className="w-25 "
                    onClick={() => {
                      navigate("/");
                    }}
                  />
                </div>

                <div className="col-lg-6   col-2 pe-5   d-flex justify-content-end ">
                  <div className="row mt-3 ">
                    <div className="col-12  d-none d-lg-block">
                      <ul className="list-inline">
                        <li
                          className="list-inline-item p-2 pe-5  fs-6"
                          style={{ cursor: "pointer", color: "white" }}
                        >
                          About Us
                        </li>
                        <li
                          className="list-inline-item p-2 fs-6 pe-5"
                          style={{ cursor: "pointer", color: "white" }}
                          onClick={() => {
                            navigate("/Service");
                          }}
                        >
                          Service
                        </li>
                        <li
                          className="list-inline-item p-2 fs-6 pe-5"
                          style={{ cursor: "pointer", color: "white" }}
                          onClick={() => {
                            navigate("/Career");
                          }}
                        >
                          Career
                        </li>
                        <li
                          className="list-inline-item p-2 fs-6 pe-5"
                          style={{ cursor: "pointer", color: "white" }}
                          onClick={() => {
                            navigate("/Contact");
                          }}
                        >
                          Contact Us
                        </li>
                      </ul>
                    </div>
                    <div className="col d-lg-none">
                      <img
                        src={hamberger}
                        alt=""
                        onClick={(e) => settoggle(!toogle)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        ""
      )}
      {toogle === true ? (
        <>
          <div className="container-fluid mobile border">
            <div className="container  ">
              <div className="row mt-5">
                <div className="col-lg-12">
                  <div className="row">
                    <div className="col-8  text-start ps-5"></div>
                    <div className="col-4">
                      <img
                        src={cross}
                        alt=""
                        onClick={(e) => settoggle(!toogle)}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="row mt-5">
                <div className="col-11 ">
                  <ul
                    className="fw-bold mt-5"
                    style={{
                      listStyle: "none",
                      textAlign: "center",
                      color: "white",
                    }}
                  >
                    <li className=" p-3  fs-5" style={{ cursor: "pointer" }}>
                      About Us
                    </li>
                    <li
                      className=" p-3 fs-5"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        navigate("/Service");
                      }}
                    >
                      Services
                    </li>
                    <li
                      className="p-3 fs-5"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        navigate("/Career");
                      }}
                    >
                      Career
                    </li>
                    <li
                      className=" p-3 fs-5"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        navigate("/Contact");
                      }}
                    >
                      Contact Us
                    </li>
                    
                  </ul>
                </div>
              </div>

              <div className="row mt-4 text-center">
                <div className="col-12">
                  <div className=" ">
                    <img src={logo} className="w-50" alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        ""
      )}

      {toogle === false ? (
        <>
          <div className="container-fluid">
            <div className="row abouthead">
              <div className="col-12 image-container">
                <div>
                  <img
                    src={photo}
                    // className="w-100 h-100"
                    style={{ width: "100%", height: "100%" }}
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="container py-5 mt-5 ">
            <div className="row">
              <div className="col-12">
                <div>
                  <h1 className="fs-1">
                    About us : Unaire Sync IT Solutions Pvt. Ltd.
                  </h1>
                </div>
                <div className="mt-5">
                  <p className="fs-5">
                    In the bustling city of Ajmer, Rajasthan, Unaire Sync IT
                    Solutions was born from a simple yet ambitious vision: to
                    revolutionise the digital landscape and empower businesses
                    with cutting-edge technology solutions. Our journey began on
                    14 July 2023 , fueled by a passion for innovation and a
                    commitment to excellence.
                  </p>
                  <p className="fs-5">
                    From humble beginnings, we quickly gained recognition as a
                    trusted partner for businesses seeking to harness the power
                    of technology. Guided by our core values of integrity,
                    transparency, and customer satisfaction, we embarked on a
                    mission to deliver impactful IT solutions that drive real
                    results.
                  </p>
                  <p className="fs-5">
                    Over the year, we have grown and evolved, adapting to the
                    ever-changing needs of our clients and the dynamic nature of
                    the digital landscape. Today, Unaire Sync IT Solutions
                    stands as a beacon of innovation, delivering comprehensive
                    IT services across a wide range of industries.
                  </p>
                  <p className="fs-5">
                    Over the year, we have grown and evolved, adapting to the
                    ever-changing needs of our clients and the dynamic nature of
                    the digital landscape. Today, Unaire Sync IT Solutions
                    stands as a beacon of innovation, delivering comprehensive
                    IT services across a wide range of industries.
                  </p>
                  <p className="fs-5">
                    What sets us apart is our relentless dedication to our
                    clients' success. We believe that by truly understanding
                    their needs and goals, we can deliver tailored solutions
                    that exceed expectations and drive tangible value. This
                    client-centric approach has earned us the trust and loyalty
                    of businesses across India.
                  </p>
                  <p className="fs-5">
                    As we look to the future, we remain committed to our
                    founding principles of innovation, collaboration, and
                    customer satisfaction. Join us on our journey as we continue
                    to push the boundaries of technology and empower businesses
                    to thrive in the digital age with Unaire Sync IT Solutions.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="container">
            <div className="row">
              <div className="col-md-4">
                <div className="">
                  <img src={mission} alt="" className="w-25" />
                </div>
                <div>
                  <h2>Mission</h2>
                </div>
                <div>
                  <p className="fs-5" style={{ textAlign: "justify" }}>
                    At Unaire Sync IT Solutions, our mission is to empower
                    businesses with innovative technology solutions that drive
                    growth, efficiency, and success. We are committed to
                    delivering exceptional value to our clients by understanding
                    their unique needs and providing tailored solutions that
                    exceed expectations. Through our passion for innovation and
                    dedication to excellence, we strive to be a trusted partner
                    in our clients' digital transformation journey.
                  </p>
                </div>
              </div>

              <div className="col-md-4">
                <div className="">
                  <img src={vision} className="w-25 " alt="" />
                </div>
                <div className="">
                  <h2>Vision</h2>
                </div>
                <div>
                  <p className="fs-5" style={{ textAlign: "justify" }}>
                    Our vision at Unaire Sync IT Solutions is to be a leading
                    provider of technology solutions, recognized for our
                    commitment to innovation, quality, and customer
                    satisfaction. We aim to create a future where businesses of
                    all sizes can leverage the power of technology to achieve
                    their goals and stay ahead in a rapidly evolving digital
                    landscape. By continuously pushing the boundaries of what's
                    possible and fostering a culture of collaboration and
                    excellence, we aspire to make a positive impact on the world
                    through our work.
                  </p>
                </div>
              </div>

              <div className="col-md-4">
                <div className="">
                  <img src={values} alt="" className="w-25" />
                </div>
                <div className="">
                  <h2>Values</h2>
                </div>
                <div>
                  <p className="fs-5" style={{ textAlign: "justify" }}>
                    At Unaire Sync IT Solutions, we uphold integrity with
                    honesty and transparency. We innovate to deliver creative
                    tech solutions and prioritize customer needs with trust. Our
                    drive for excellence ensures continuous improvement.
                    Collaboration harnesses diverse talents for success.
                    Adaptability and accountability foster growth. Social
                    responsibility guides our community engagement and
                    environmental efforts.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="container py-4  mt-5">
            <div className="row">
              <div className="col-12">
                <div className="mb-5">
                  <h1 className="fs-1">
                    Diversity, Equity, and Inclusion Efforts
                  </h1>
                </div>

                <div className="">
                  <span className="fs-3 fw-bold ">Community Engagement</span>
                  <p className="mt-3 fs-5 mb-5">
                    We believe in the power of giving back to the community.
                    Through various initiatives and partnerships, we actively
                    contribute to charitable causes, volunteer programs, and
                    community development projects. By engaging with the
                    community, we aim to make a meaningful difference in the
                    lives of those around us.
                  </p>
                </div>
                <div>
                  <span className="fs-3 fw-bold">
                    Corporate Social Responsibility
                  </span>
                  <p className="mt-3 fs-5 mb-5">
                    At Unaire Sync IT Solutions, we take our corporate social
                    responsibility seriously. We are committed to ethical
                    business practices, environmental sustainability, and social
                    impact. Through responsible sourcing, waste reduction
                    efforts, and philanthropic activities, we strive to create a
                    positive influence on society and the environment.
                  </p>
                </div>

                <div>
                  <span className="fs-3 fw-bold">Employee Well-being</span>

                  <p className="mt-3 fs-5 mb-5">
                    Our employees are our most valuable asset. In this section,
                    we emphasize our dedication to creating a supportive and
                    inclusive work environment where employees can thrive. From
                    wellness programs and professional development opportunities
                    to flexible work arrangements and employee recognition
                    initiatives, we prioritize the well-being and growth of our
                    team members.
                  </p>
                </div>

                <div>
                  <span className="mt-3 fs-3 fw-bold">
                    Diversity and Inclusion
                  </span>
                  <p className="mt-3 fs-5 mb-5">
                    Diversity and inclusion are fundamental to who we are as a
                    company. We celebrate diversity in all its forms and
                    actively promote an inclusive culture where everyone feels
                    valued, respected, and empowered to contribute their unique
                    perspectives. By fostering a diverse and inclusive
                    workplace, we drive innovation, creativity, and
                    collaboration.
                  </p>
                </div>
                <div>
                  <span className="fs-3 fw-bold">Employee Engagement</span>
                  <p className="mt-3 fs-5 mb-5">
                    We believe that engaged employees are the key to our
                    success. In this section, we highlight our efforts to foster
                    a culture of engagement and empowerment. Through regular
                    communication, feedback mechanisms, team-building
                    activities, and opportunities for personal and professional
                    growth, we ensure that our employees are motivated,
                    fulfilled, and invested in the company's mission and vision.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <footer>
            <div className="container-fluid foot ">
              <div className="container">
              <div className="row ">
                <div className="col-sm-7  mt-5">
                  <div className=" ps-sm-5 col-md-7 ps-3 text-sm-start text-center" 
                 >
                    <img src={logo} alt="" className="   w-50" onClick={() => UPTOP("/")}  style={{cursor:"pointer"}}/>
                  </div>
                </div>

                <div className="col-sm-5 col-md-5 justify-content-end">
                  <div className="row mt-5">
                    <div className="col-12  text-center  ">
                      <ul
                        className=" d-sm-flex ps-sm-5  "
                        style={{ listStyle: "none" }}
                      >
                        <li
                          className="  pe-4   p-2  fs-5"
                          style={{ cursor: "pointer", color: "white" }}
                        >
                          About Us
                        </li>
                        <li
                          className=" pe-4 p-2  fs-5"
                          style={{ cursor: "pointer", color: "white" }}
                          onClick={() => UPTOP("/Service")}
                        >
                          Service
                        </li>
                        <li
                          className=" pe-4 p-2  fs-5"
                          style={{ cursor: "pointer", color: "white" }}
                          onClick={() => UPTOP("/Career")}
                        >
                          Career
                        </li>
                        <li
                          className="  p-2 pe-4  fs-5"
                          style={{ cursor: "pointer", color: "white" }}
                          onClick={() => UPTOP("/Contact")}
                        >
                          Contact Us
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>

                <div className=" d-flex justify-content-center align-items-center">
                  <div className="pe-4">
                    <a
                      href="https://x.com/UnaireSync"
                      className="link"
                      target="blank"
                    >
                      <img src={x} alt="" />
                    </a>
                  </div>
                  <div className="pe-4">
                    <a
                      href="https://www.facebook.com/unairesync"
                      className="link"
                      target="blank"
                    >
                      <img src={facebook} alt="" />
                    </a>
                  </div>
                  <div className="pe-4">
                    <a
                      href="https://www.instagram.com/unairesync_itsolution/"
                      className="link"
                      target="blank"
                    >
                      <img src={instagram} alt="" />
                    </a>
                  </div>
                  <div className="pe-4">
                    <a
                      href="https://in.pinterest.com/unairesync/"
                      className="link"
                      target="blank"
                    >
                      <img src={pineinterest} alt="" />
                    </a>
                  </div>
                  <div className="pe-4">
                    <a
                      href="https://www.youtube.com/@UnaireSync"
                      className="link"
                      target="blank"
                    >
                      <img src={Youtube} alt="" />
                    </a>
                  </div>
                  <div className="pe-4">
                    <a
                      href="https://in.linkedin.com/company/unaire-sync-it-solutions"
                      className="link"
                      target="blank"
                    >
                      <img src={linkedin} alt="" />
                    </a>
                  </div>
                  <div className="pe-4">
                      <a
                        href="https://wa.me/917742691107?text=urlencodedtext"
                        className="link "
                        target="blank"
                      >
                        <img src={watapp} alt="" className="w-75"  />
                      </a>
                    </div>
                </div>
              </div>

              <div className="row mt-3">
                <div className="col-sm-12 text-center">
                  <h5 className="fs-6" style={{ color: "white" }}>
                    &#169;UnaireSyncITSolution All Rights Reserved 2024
                  </h5>
                </div>
              </div>
              </div>
            </div>
          </footer>
        </>
      ) : (
        ""
      )}
    </div>
  );
}

export default AboutUs;
